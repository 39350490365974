<template>
  <div class="user-result">
	<div id="canvas" v-if="resultInfo !== ''">
		<div class="user-title">您的个人信息</div>
		<div class="user-info">
			<div class="content">
				<h1>姓名:  {{form.username}}</h1>
				<h1>性别:  {{Number(form.gender) === 0 ? '女': '男'}}</h1>
			</div>
			<div style="width:100%;">
				<h1>生辰:  {{form.date}} {{form.hour}}时</h1>
				<p style="padding-left:100rpx;">{{resultInfo.cookies.lDate}}</p>
			</div>
		</div>
		<div class="content">
			<div class="title">爱情分析</div>
			<div class="item">
				<div>
					<h1>{{resultInfo.rglm.aqfx}}</h1>
				</div>
				<h1 class="tit">桃花:</h1>
				<h1 v-html="resultInfo.returns.data.zonghe.th"></h1>
			</div>
			<div class="title">姻缘分析</div>
			<div class="item">
				<div>
					<h1  class="tit">流年感情运程:</h1>
					<p v-html="resultInfo.returns.info.sxgx.yinyuana"></p>
					<h1  class="tit">感情姻缘总评:</h1>
					<p v-html="resultInfo.returns.info.sxgx.yinyuanb"></p>
					<h1  class="tit">婚配建议和补救:</h1>
					<p v-html="resultInfo.returns.info.sxgx.yinyuanc"></p>
				</div>
			</div>
		</div>
	</div>
	<div class="loading-items" id="loading-items" v-if="resultInfo === ''">
		<div class="loading">
			<div class="loading_mask"></div>
			<div class="weui-loadmore">
			<div class="weui-loading"></div>
			<div class="weui-loadmore__tips">测算结果正在加载中 大约5-7秒 请不要退出~</div>
			</div>
		</div>
	</div>
	<div class="loading-items" id="loading-items" v-if="isConver">
      <div class="loading">
        <div class="loading_mask"></div>
        <div class="weui-loadmore">
        	<div class="weui-loading"></div>
        	<div class="weui-loadmore__tips">结果图片生成中，请不要退出~</div>
      	</div>
	  </div>
	</div>
	<div class="button-items" v-if="resultInfo !== ''">
      <div class="notice">
        为保护您的隐私 系统不记录测算结果
      </div>
      <input type="button" :disabled="isConver ? true : false" value="点击生成图片" @click="saveImg" />
    </div>
  </div>
</template>
<style src="./style.css"></style>
<script>
  import html2canvas from 'html2canvas'
  import { getYYCS } from '../api/index'
  export default{
	data() {
	  return {
		resultInfo: '',
		imagePhoto: '',
		height: 0,
		form: {},
		isConver: false
	  }
	},
	mounted() {
	  //console.log(this.$route.query)
	  const params = {
		username: this.$route.query.username,
		gender:this.$route.query.gender,
		date: this.$route.query.date,
		hour:this.$route.query.hour
	  }
	  this.form = params
	//   console.log(params)
	  if(params.username) {
		this.initData(params)
	  }
	},
	methods: {
	  async initData(params) {
		const res = await getYYCS(params)
		if(res.data) {
			console.log(res.data)
			this.resultInfo =  res.data
		}
	  },
	  taskScreenshort(dom) {
		//const dom = document.getElementById("canvas")
		const width = dom.offsetWidth
		this.height = dom.offsetHeight
		let scale = 2 // 设定倍数这样不会模糊
		return new Promise((resolve,reject) => {
			html2canvas(dom, {
				allowTaint: true, //允许被污染
				width: width,
				heigth: this.height,
				windowHeight: this.height,
				windowWidth: width,
				scale: scale,
				scrollX: 0,
				scrollY: 0,
				y: 0,
				x: -10,
				useCORS: true
			}).then(canvas => {
				resolve(canvas)
			}).catch(err => {
				reject(err)
			})
		})
       },
	   saveImg() {
		//alert(document.getElementById("canvas"))
		this.isConver = true;
		this.taskScreenshort(document.getElementById("canvas")).then(canvas => {
			this.imagePhoto = canvas.toDataURL('image/png', 1.0) //将图片转为base64
			localStorage.setItem('base64', this.imagePhoto)
			//console.log(this.imagePhoto)
			this.isConver = false
			window.location.href= `#/showImg?height=${this.height}`;
			
		})
	   }
	}
  }
</script>