<template>
  <div class="form">
    <ul>
      <li>
        <label>课程名称：</label>
        <input  v-model="form.title" disabled="disabled" />
      </li>
      <li>
        <label>课程价格：</label>
        <input  v-model="form.price" disabled="disabled"/>
      </li>
      <li>
        <label>课程截止日期：</label>
        <input  value="2024-12-31" disabled="disabled"/>
      </li>
      <li>
        <label>手机号：</label>
        <input  v-model="form.mobile" />
      </li>
       <li>
        <label>您的姓名：</label>
        <input  v-model="form.username" />
      </li>
    </ul>
    <div class="button">
      <input type="button" value="提交信息" @click="submit" style="height:60px"/>
    </div>
  </div>
</template>
<script>
import { initForm } from '../api/index'
export default{
  data() {
    return{
      form:{}
    }
  },
  created() {
     this.form.title= this.$route.query.title
     this.form.price= `¥${this.$route.query.price}`

  },
  methods:{
    async submit(){
      if (!this.form.mobile) {
        alert('请输入手机号')
        return false
      }
      if (!this.form.username) {
        alert('请输入用户名')
        return false
      }
      const res = await initForm({mobile:this.form.mobile,username:this.form.username})
      if(res.code === 200) {
        alert("恭喜你，报名成功！")
        this.$router.push({path: '/class_list'})
      }
    }
  }
}
</script>

<style src="./style.css"></style>
<style scoped>
  .form li{
    padding:10px;
    display: flex;
    align-items: center;
  
  }
   .form li >label{
    flex-shrink: 0;
    display: block;
    width:100px;
    font-size: 14px;
    font-weight: bold;
   }
  .form li > input{
    width:100%;
    height:35px;
    border:1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    padding-left:10px;
  }
  .button{
    text-align: center;
  
  }
  .button > input{
    margin-top: 40px;
    width: 90%;
    border:1px solid #F7B336;
    background: #F7B336;
    border-radius: 50px;
    color: #000;
    text-align: center;
    padding:15px;
      font-size: 16px;
      font-weight: bold;
      outline: none;
  }
</style>