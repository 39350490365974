<template>
  <div>
    <!--pages/xmpd_result/index.wxml-->
    <div id="canvas" class="user-result" v-if="resultInfo !== ''">
      <div class="sex-box">
        <div bindtap="addEvent" data-type='boy'>
          <div class="icon icon-boy">
            <img src="https://dekangzhijia.com/static/icon-5.png" />
          </div>
          <div class="content">
            {{resultInfo.returns.one_arr.name1}}
          </div>
        </div>
        <div class="icon-1">
          <img src="https://dekangzhijia.com/static/moon_vs.png" /> 
        </div>
        <div bindtap="addEvent" data-type='girl'>
          <div class="icon">
            <img src="https://dekangzhijia.com/static/icon-4.png" />
          </div>
          <div class="content">
            {{resultInfo.returns.two_arr.name2}}
          </div>
        </div>
      
      </div>
      <div class="content">
        <div class="title">匹配契合度</div>
        <div  style="background-color: none;">
          <div>
            <template v-if="isPoint">
              <div class="list">
                <img v-for="i in matching" :key="i" src="https://dekangzhijia.com/static/star.png" />
                <img src="https://dekangzhijia.com/static/star2.png" />
              </div>
            </template>
            <template v-else>
              <div class="list">
                <img v-for="i in matching" :key="i" src="https://dekangzhijia.com/static/star.png" />
              </div>
            </template>
          </div>
        </div>
        <div class="title">男女姓名婚姻五格</div>
        <div class="item">
          <div>
            <h1 class="tit boy">{{resultInfo.returns.one_arr.name1}}</h1>
          </div>
          <div>
            <h1 class="tit">天格: </h1>
            <h1>{{resultInfo.returns.tdrh_ge_arr.tiange1}}  ({{resultInfo.returns.tdrh_ge_arr.tgsancai}})</h1>
          </div>
          <div>
            <h1  class="tit">人格: </h1>
            <h1>{{resultInfo.returns.tdrh_ge_arr.renge1}}  ({{resultInfo.returns.tdrh_ge_arr.renge1_sancai133}})</h1>
          </div>
          <div>
            <h1  class="tit">地格:</h1>
            <h1> {{resultInfo.returns.tdrh_ge_arr.dige1}}  ({{resultInfo.returns.tdrh_ge_arr.dige1_sancai133}})</h1>
          </div>
          <div>
            <h1  class="tit">外格: </h1>
            <h1>{{resultInfo.returns.tdrh_ge_arr.waige1}}  ({{resultInfo.returns.tdrh_ge_arr.waige1_sancai133}})</h1>
          </div>
          <div>
            <h1  class="tit">总格: </h1>
            <h1>{{resultInfo.returns.tdrh_ge_arr.zhongge1}}  ({{resultInfo.returns.tdrh_ge_arr.zhongge1_sancai133}})</h1>
          </div>
        </div>
        <div class="item">
          <div>
            <h1 class="tit girl">{{resultInfo.returns.two_arr.name2}}</h1>
          </div>
          <div>
            <h1 class="tit">天格: </h1>
            <h1>{{resultInfo.returns.tdrh2_ge_arr.ntiange1}}  ({{resultInfo.returns.tdrh2_ge_arr.tg2sancai}})</h1>
          </div>
          <div>
            <h1  class="tit">人格: </h1>
            <h1>{{resultInfo.returns.tdrh2_ge_arr.nrenge1}}  ({{resultInfo.returns.tdrh2_ge_arr.nrenge1_sancai133}})</h1>
          </div>
          <div>
            <h1  class="tit">地格:</h1>
            <h1> {{resultInfo.returns.tdrh2_ge_arr.ndige1}}  ({{resultInfo.returns.tdrh2_ge_arr.tg2sancai}})</h1>
          </div>
          <div>
            <h1  class="tit">外格: </h1>
            <h1>{{resultInfo.returns.tdrh2_ge_arr.nwaige1}}  ({{resultInfo.returns.tdrh2_ge_arr.nwaige1_sancai133}})</h1>
          </div>
          <div>
            <h1  class="tit">总格: </h1>
            <h1>{{resultInfo.returns.tdrh2_ge_arr.nzhongge1}}  ({{resultInfo.returns.tdrh2_ge_arr.nzhongge1_sancai133}})</h1>
          </div>
        </div>
        <div class="title">双方性格及原神</div>
        <div class="name boy">{{resultInfo.returns.one_arr.name1}}</div>
        <div class="item2">
          <h1 class="tit">性格</h1>
          <p v-html="resultInfo.returns.tdrh_ge_arr.sancai.xg"></p>
          <h1 class="tit">原神</h1>
          <p>{{resultInfo.returns.tdrh_ge_arr.yuanshen.yuanshen}}</p>
          <h1 class="tit">原神属性</h1>
          <p>{{resultInfo.returns.tdrh_ge_arr.yuanshen.wh}}</p>
          <h1 class="tit">原神个性</h1>
          <p>{{resultInfo.returns.tdrh_ge_arr.yuanshen.msg}}</p>
        </div>
        <div class="name girl"> {{resultInfo.returns.two_arr.name2}}</div>
        <div class="item2">
          <h1 class="tit">性格</h1>
          <p v-html="resultInfo.returns.tdrh2_ge_arr.sancai.xg"></p>
          <h1 class="tit">原神</h1>
          <p>{{resultInfo.returns.tdrh2_ge_arr.yuanshen.yuanshen}}</p>
          <h1 class="tit">原神属性</h1>
          <p>{{resultInfo.returns.tdrh2_ge_arr.yuanshen.wh}}</p>
          <h1 class="tit">原神个性</h1>
          <p>{{resultInfo.returns.tdrh2_ge_arr.yuanshen.msg}}</p>
        </div>
      </div>
    </div>
    <div class="loading-items" id="loading-items" v-if="resultInfo === ''">
      <div class="loading">
        <div class="loading_mask"></div>
        <div class="weui-loadmore">
        <div class="weui-loading"></div>
        <div class="weui-loadmore__tips">测算结果正在加载中 大约5-7秒 请不要退出~</div>
        </div>
      </div>
    </div>
    <div class="loading-items" id="loading-items" v-if="isConver">
      <div class="loading">
        <div class="loading_mask"></div>
        <div class="weui-loadmore">
        	<div class="weui-loading"></div>
        	<div class="weui-loadmore__tips">结果图片生成中，请不要退出~</div>
      	</div>
	    </div>
	  </div>
    <div class="button-items" v-if="resultInfo !== ''">
      <div class="notice">
        为保护您的隐私 系统不记录测算结果
      </div>
      <input type="button" :disabled="isConver ? true : false" value="点击生成图片" @click="saveImg" />
    </div>
  </div>
</template>
<script>
import html2canvas from 'html2canvas'
import { getXMPD } from '../api/index'
  export default{
    data() {
      return {
        resultInfo: '',
        imagePhoto: '',
        height: 0,
        form: {},
        isPoint: false,
        matching:2,
        isConver: false
      }
    },
    mounted() {
    console.log(this.$route.query)
      const params = {
        malexing: this.$route.query.malexing,
        malename:this.$route.query.malename,
        femalexing: this.$route.query.femalexing,
        femalename:this.$route.query.femalename
      }
      this.form = params
      console.log(params)
      if(params.malexing) {
        this.initData(params)
      }
    },
	methods: {
	  async initData(params) {
      const res = await getXMPD(params)
      if(res.data) {
        console.log(res.data)
        this.resultInfo =  res.data
        let matching = '';
        if (res.data.returns.matching !== undefined) {
          matching = String(res.data.returns.matching)
        }
        if(/^\d+\.\d+$/.test(matching)){
          console.log(matching.substring(0,1))
          
          this.matching =  Number(matching.substring(0,1)),
          this.isPoint = true
        } else {
          this.matching =  Number(matching),
          this.isPointisPoint =  false
        }
      }
	  },
	  taskScreenshort(dom) {
      //const dom = document.getElementById("canvas")
      const width = dom.offsetWidth
      this.height = dom.offsetHeight
      let scale = 2 // 设定倍数这样不会模糊
      return new Promise((resolve,reject) => {
        html2canvas(dom, {
          allowTaint: true, //允许被污染
          width: width,
          heigth: this.height,
          windowHeight: this.height,
          windowWidth: width,
          scale: scale,
          scrollX: 0,
          scrollY: 0,
          y: 0,
          x: -10,
          useCORS: true
        }).then(canvas => {
          resolve(canvas)
        }).catch(err => {
          reject(err)
        })
      })
    },
	  saveImg() {
      //alert(document.getElementById("canvas"))
      this.isConver = true
      this.taskScreenshort(document.getElementById("canvas")).then(canvas => {
        this.imagePhoto = canvas.toDataURL('image/png', 1.0) //将图片转为base64
        localStorage.setItem('base64', this.imagePhoto)
        //console.log(this.imagePhoto)
        this.isConver = false
        window.location.href= `#/showImg?height=${this.height}`;
        
      })
	  }
  }
}
</script>