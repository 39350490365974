import { createRouter, createWebHashHistory } from 'vue-router'
import result from "../pages/result.vue"
import result_aiqing from "../pages/result_aiqing.vue"
import result_hehun from "../pages/result_hehun.vue"
import result_xmpd from "../pages/result_xmpd.vue"
import result_test from "../pages/result_test.vue"
import showImg from "../pages/showImg.vue"
import class_list from "../pages/class_list.vue"
import class_content from "../pages/class_content.vue"
import class_form from "../pages/class_form.vue"
import star from "../pages/star.vue"
//import star_content from "../pages/star_content.vue"
import star_content_2 from "../pages/star_content_2.vue"
//import notFound from"../pages/404" ;
const router = createRouter({
  history: createWebHashHistory(),
  mode: "hash",
  routes: [
	{
	  path: "/result",
	  name: 'result',
	  component: result,
	  meta: {
		title:'八字命理结果'
	  }
	},
	{
		path: "/result_aiqing",
		name: 'result_aiqing',
		component: result_aiqing,
		meta: {
		  title:'爱情姻缘结果'
		}
	},
	{
		path: "/result_hehun",
		name: 'result_hehun',
		component: result_hehun,
		meta: {
		  title:'八字合婚结果'
		}
	},
	{
		path: "/result_xmpd",
		name: 'result_xmpd',
		component: result_xmpd,
		meta: {
		  title:'姓名配对结果'
		}
	},
	{
		path: "/showImg",
		name: 'showImg',
		component: showImg,
		meta: {
		  title:'保存结果'
		}
	},
	{
		path: "/class_list",
		name: 'class_list',
		component: class_list,
		meta: {
		  title:'课程列表'
		}
	},
	{
		path: "/result_test",
		name: 'result_test',
		component: result_test,
		meta: {
		  title:'测试 '
		}
	},
	{
		path: "/class_content",
		name: 'class_content',
		component: class_content,
		meta: {
		  title:'课程介绍'
		}
	},
	{
		path: "/class_form",
		name: 'class_form',
		component: class_form,
		meta: {
		  title:'提交报名信息'
		}
	},
	{
		path: "/star",
		name: 'star',
		component: star,
		meta: {
		  title:'星座娱乐'
		}
	},
	// {
	// 	path: "/star_content",
	// 	name: 'star_content',
	// 	component: star_content,
	// 	meta: {
	// 	  title:'星座娱乐'
	// 	}
	// },
	{
		path: "/star_content",
		name: 'star_content',
		component: star_content_2,
		meta: {
		  title:'星座查询结果'
		}
	}
	// {
	// 	path: "/404",
	// 	name: "notFound",
	// 	component: notFound,
	// 	hidden: true
	//   },
	// {
	// 	path: "/:/*", // 此处需特别注意置于最底部
	// 	redirect: "/404"
	// }
  ]
})
//router设置页面标题
router.beforeEach((to, from, next) => {
	/* 路由发生变化修改页面title */
	if (to.meta.title) {
		document.title = to.meta.title
	}
	next()
})
export default router