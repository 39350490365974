<template>
  <div class="class_content">
    <div class="title">
        <img src="https://dekangzhijia.com/static/temp_ico.jpg" />
        <h1>{{title}}</h1>
        <p>价格: ¥{{price}}</p>
        <p>截止日期:2024-12-31</p>
    </div>
    <div class="content">
      <p>课程内容:</p>
      <img :src="src" />
    </div>
    <div class="button-items">
      <input type="button" value="立即报名" @click="saveImg" />
    </div>
  </div>
</template>
<style src="./style.css"></style>
<script>
  export default{
    data() {
      return {
        src: "",
        title: "",
        price: ""
      }
    },
    created() {
      const index = this.$route.query.index
      this.title= this.$route.query.title
      this.price= this.$route.query.price
      this.src = `https://dekangzhijia.com/class/class_${index < 10 ? '0'+index : index}.jpg`
    },
    methods: {
      saveImg() {
        this.$router.push({path: '/class_form', query: {title:this.title,price:this.price}})
      }
    }
  }
</script>

<style scoped>
  .class_content{
    overflow-x: hidden;
  }
  .class_content > .title img{
    width: 100%;
    height:350px;
    border:1px solid #f5f7f8;
  }
  .class_content > .title  h1{
    font-size: 24px;
    font-weight:bold;
    padding: 0 10px;
  }
    .class_content > .title p{
      font-size: 14px;
    font-weight:bold;
    padding-left:10px;
    }
  .class_content .content {
    padding:10px 10px 150px 10px;
  }
  .class_content .content p{
      font-size: 14px;
    font-weight:bold;
    }
  .class_content .content > img{
    max-width:100%;
    height: 100%;
  }
  .button-items{
    height:50px;
    padding-top: 40px;
  }
</style>