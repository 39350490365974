<template>
    <div class="star">
      <!-- <div class="banner">
        <img src="https://dekangzhijia.com/static/banner.jpg" />
      </div>
      <ul class="list">
        <li v-for="(item) in 12" :key="item" @click="getDetail(item)">
            <img :src="'https://dekangzhijia.com/static/star_icon_'+item+'.jpg'" />
        </li>
      </ul> -->
      <div class="ipt">
        <h1 class="title">星座查询</h1>
        <input  class="text"/>
        <div class="bottom">
          <input type="button"  value="开始查询" @click="getDetail"/>
        </div>
      </div>
    </div>
</template>
<script>
    export default{
        methods: {
            getDetail(){
              //this.$router.push({path: '/star_content',query: {index} });
              this.$router.push({path: '/star_content' });
            }
        }
    }
</script>
<style src="./style.css"></style>
<style scoped>
  .star{
    background: url(https://dekangzhijia.com/static/star_bg.jpg) no-repeat;
    height: 100vh;
    background-size: cover;
    width: 100%;
  }
  .star .ipt{
    position: fixed;
    top:30%;
    left:50%;
    transform: translate(-50%, -10%);
    width: 100%;
    text-align: center;
  }
  .star .ipt .title{
    color:#fff;
    font-weight: bold;
    font-size: 30px;
    text-align: center;
    padding-bottom: 30px;
  }
  .star .ipt .text{
    width:80%;
    height: 40px;
    border-radius: 20px;
    border:1px solid #f5f7f8;
    outline: none;
    padding-left:20px;
    font-size: 20px;
  }
  .star .ipt .bottom{
    text-align: center;
    padding-top: 30px;
  }
  .star .ipt .bottom > input{
     background: #87ceeb;
     padding:10px 20px;
     border-radius: 20px;
     width: 60%;
     text-align: center;
     font-size: 20px;
     border:none;
     font-weight:bold;
  }
  /* .star .title{
    font-size:30px;
    font-weight: bold;

  }
  .star .banner > img{
    max-width: 100%;
  }
  .star .list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
  }
  .star .list li{
    width:calc((100% - 10px ) /3);
    text-align: center;

  }
  .star .list img{
    width: 50px;
    
  } */
</style>